<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-card class="pa-4 mt-1">
      <v-row
        v-if="snapshots.length > 0"
      >
        <v-col
          cols="12"
        >
          <v-select
            v-model="plsnapshotid"
            :items="snapshots"
            item-value="plsnapshotid"
            label="Дата изменений"
            @change="loadNewSnapshot"
          >
            <template v-slot:item="{ item }">
              {{ item.snapshotdtstr }}
              <span class="ml-5">автор: {{ item.usertitle }}</span>
            </template>
            <template v-slot:selection="{ item }">
              {{ item.snapshotdtstr }}
              <span class="ml-5">автор: {{ item.usertitle }}</span>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <div
        v-if="dataMismatch"
        class="text-center my-4"
      >
        <div
          class="pa-6 text-uppercase"
        >
          Нет данных
        </div>
        <br>
        <v-btn
          color="blue"
          class="mr-0 mb-3"
          @click="$router.push('/')"
        >
          на главную
        </v-btn>
      </div>
      <span
        v-if="!dataLoading && !dataMismatch && tableSnap.info"
      >
        <table-vf
          :can-edit="canEditReport"
          :show-table-title="true"
          :table="tableSnap"
        />
      </span>
    </v-card>
    <data-loading />
  </v-container>
</template>

<script>
  import dataLoading from '../components/dataLoading'
  import tableVf from './tableVfViewLite'
  import { mapGetters } from 'vuex'

  export default {
    name: 'PlanningSnapshot',
    components: {
      dataLoading,
      tableVf,
    },
    data () {
      return {
        table: {},
        tableSnap: {},
        canEditReport: false,
        pltableid: null,
        plsnapshotid: null,
        dataMismatch: false,
        snapshots: [],
      }
    },
    computed: {
      ...mapGetters(['tableStyles', 'plTablesChanges', 'getObjectsList']),
      dataLoading () {
        return this.$store.getters.loading
      },
    },
    mounted () {
      if (this.tableStyles === null) {
        this.$store.dispatch('getTableStyles')
      }
      this.pltableid = this.$route.query.pltableid
      this.plsnapshotid = parseInt(this.$route.query.plsnapshotid)
      if (this.pltableid && this.plsnapshotid) {
        const payload = {
          pltabledataid: this.pltableid,
          plsnapshotid: this.plsnapshotid,
        }
        this.$store.dispatch('setDataLoading', true)
        const getTableSnapshot = this.$store.dispatch('getTableSnapshot', payload)
        const getSnapshotList = this.$store.dispatch('getSnapshotsList', this.pltableid)
        const actualTableData = this.$store.dispatch('getTableData', this.pltableid)
        Promise.all([getSnapshotList, getTableSnapshot, actualTableData]).then((resp) => {
          this.tableSnap = resp[1]
          this.table = resp[2]
          this.snapshots = resp[0]
          this.$store.dispatch('setDataLoading', false)
        }).catch((e) => {
          this.$store.dispatch('setDataLoading', false)
          this.dataMismatch = true
        })
      } else {
        this.dataMismatch = true
      }
    },
    methods: {
      loadNewSnapshot () {
        const payload = {
          pltabledataid: this.pltableid,
          plsnapshotid: this.plsnapshotid,
        }
        this.$store.dispatch('setDataLoading', true)
        this.$store.dispatch('getTableSnapshot', payload).then((resp) => {
          this.tableSnap = resp
          this.$store.dispatch('setDataLoading', false)
        }).catch((e) => {
          this.$store.dispatch('setDataLoading', false)
          this.dataMismatch = true
        })
      },
    },
  }
</script>

<style>
@import '../../../assets/dx.llight.css';
/* stylelint-disable */
#gridContainer {
  height: 440px;
}
/* stylelint-enable */
td {
  padding: 0!important;
}
.v-tabs .v-tabs-bar .v-tab.v-tab--active.active-tab {
  color: #3c4858;
}
.v-tabs .v-tabs-bar .v-tab.v-tab--active.active-tab {
  background-color: #eaeaea;
}
.version-label {
  position: absolute;
  z-index: 2;
  right: 20px;
  top: -12px;
  height: 25px;
  border-radius: 10px;
  background-color: #e5e5e5;
}
.sticky {
  position: sticky;
  top: 0;
  z-index: 1;
}

.sticky-2 {
  position: sticky;
  top: 35px;
  background: #FFFFFF;
  z-index: 1;
  /*border-bottom: 1px solid #797979;*/
  box-shadow: 0 2px 4px -2px rgba(77, 94, 71, 0.45);
}
.v-tabs-bar {
  height: 35px!important;
}
</style>
